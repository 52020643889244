* {
    --color: #000000de;
    --hover: #0000000a;
    --background: #1976d2;
    --light-background: #47a3ff;
    --gray: #00000061;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.datepicker_modal.open {
    transition: all 0.15s ease-in;
    visibility: visible;
    opacity: 1;
}
.datepicker_modal.close {
    transition: all 0.15s ease-in;
    visibility: hidden;
    opacity: 0;
}
.datepicker_modal {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.2);
    .datepicker {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 90%;
        min-width: 200px;
        width: 310px;
        border-radius: 4px;
        overflow: hidden;
        .overboxPicker {
            max-height: 432px;
            max-height: 80%;
            overflow-y: auto;
            .datenow {
                padding: 8px 20px;
                background-color: var(--background);
                p {
                    padding: 5px;
                    border-radius: 4px;
                    &:hover {
                        background-color: #00000014;
                        cursor: pointer;
                    }
                }
                .dateRange {
                    display: flex;
                    align-items: center;
                    .seprateDate {
                        color: #fff;
                        font-size: 1rem;
                        font-weight: bold;
                        margin: 0 8px;
                    }
                    .date_string {
                        font-size: 1.7rem;
                        font-weight: 400;
                        line-height: 1.17;
                        letter-spacing: 0.00735em;
                        color: #ffffff;
                        user-select: none;
                    }
                }
                .year {
                    display: inline-block;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.75;
                    letter-spacing: 0.00938em;
                    color: #ffffff8a;
                    user-select: none;
                }
            }
            .calendar {
                background-color: #ffffff;
                padding: 15px;
                .selectedDate {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 -15px;
                    h6 {
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        letter-spacing: 0.00938em;
                    }
                }
                .days {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    grid-auto-columns: minmax(41px, auto);
                    grid-auto-rows: 40px;
                    .dayInWeek span {
                        display: inline-block;
                        width: 100%;
                        line-height: 41px;
                        text-align: center;
                        color: var(--gray);
                        font-size: 0.75rem;
                        font-weight: 400;
                        letter-spacing: 0.03333em;
                        user-select: none;
                    }
                    .day {
                        .selected {
                            color: #ffffff;
                            background-color: var(--background);
                            pointer-events: none;
                        }
                        .inValid {
                            color: var(--gray);
                            pointer-events: none;
                        }
                        .belongToRange {
                            color: #ffffff;
                            background-color: var(--light-background);
                            pointer-events: none;
                        }
                        span {
                            display: inline-block;
                            width: 100%;
                            line-height: 41px;
                            text-align: center;
                            color: var(--color);
                            font-size: 0.875rem;
                            font-weight: 400;
                            letter-spacing: 0.01071em;
                            user-select: none;
                            &:hover {
                                cursor: pointer;
                                background-color: var(--hover);
                            }
                        }
                    } 
                }
                .yearsBox {
                    height: 300px;
                    .yearPicker {
                        height: 100%;
                        overflow-y: scroll;
                        p {
                            text-align: center;
                            padding: 15px;
                            cursor: pointer;
                        }
                        .selectedYear {
                            font-size: 1.5rem;
                            font-weight: 500;
                            color: var(--background);
                        }
                    }
                }
            }
        }
        .actionsPicker {
            background-color: #fff;
            padding: 0 15px 8px;
            display: flex;
            justify-content: flex-end;
            max-height: 20%;
            max-height: 44px;
            button {
                color: var(--background);
            }
        }
    }
}